import React, { useContext, useEffect, memo } from 'react';
import { CreditcardContext } from '../../context/creditcard.context';
import { diffTwoDatesInSeconds as diff, isTrue, isGreaterThan, isUndefined } from '../../utils/functions';
import { redirectWhenStatusPageIsNotValid, navigateToError } from '../../utils/navigate';
import { Container } from '../../styles/pages';
import { LinkStyled } from '../../styles/common/links';
import pages from '../../constants/pages.json';
import { LoadingComponent } from '../../components/loading';
import Layout from '../../components/responsive/layout';
import Portrait from '../../components/responsive/portrait';
import { CompanyContext } from '../../context/company.context';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../context/lang.context';
import { getMultiCompanyPath } from '../../utils/navigate';

const RenderLayout = memo(
  ({ render, search, data }: any) => {
    if (!render) {
      return <LoadingComponent />;
    }
    const { t } = useContext(LangContext);

    const dataGQL = useStaticQuery(graphql`
      query {
        allI18NJson {
          nodes {
            locale
            clientId
            WELCOME {
              TITLE
              SUBTITLE
              START
            }
          }
        }
      }
    `);

    return (
      <Layout>
        <Portrait>
          <Container className="content relative">
            <h1>{t(dataGQL).WELCOME.TITLE}</h1>
            <p>{t(dataGQL).WELCOME.SUBTITLE}</p>
            <img className="w-full my-6 md:w-3/4" loading="eager" src={data} />
            <LinkStyled to={`${getMultiCompanyPath(pages.ADD)}${search}`} replace>
              {t(dataGQL).WELCOME.START}
            </LinkStyled>
          </Container>
        </Portrait>
      </Layout>
    );
  },
  ({ render: prev }, { render: next }) => prev === next
);

const IndexPage = ({ search, getToken }: PageProps) => {
  const { infoUser, timeStamp, getCard } = useContext(CreditcardContext);
  const allValid = [Object.entries(infoUser).length, timeStamp?.length ?? 0].every(isGreaterThan(0));
  const token: string = getToken();
  const { getPublicURL }= useContext(CompanyContext);
  const card = getPublicURL(`/theme/assets/images/card/card.png`);

  useEffect(() => {
    if ((!infoUser?.expirationTime || !infoUser?.tokenExID) && token) {
      getCard?.(token);
    } else if (isUndefined(token)) {
      navigateToError()?.();
    }
  }, []);

  useEffect(() => {
    if (allValid) {
      redirectWhenStatusPageIsNotValid(infoUser, timeStamp);
    }
  }, [infoUser, timeStamp]);

  const dateDiff = diff(timeStamp ?? '', infoUser?.expirationTime ?? '');
  const render = [allValid, !!dateDiff, !infoUser.status].every(isTrue);
  return <RenderLayout render={render} search={search} data={card} />;
};

export default IndexPage;
