import React, { ReactNode, useContext } from 'react';
import Topbar from '../../components/responsive/topbar';
import { CompanyContext } from '../../context/company.context';

interface Props {
  className?: string;
  children?: ReactNode;
  bgImage?: string;
}

const Portrait = ({ className = '', children, bgImage }: Props) => {
  const { getPublicURL } = useContext(CompanyContext);
  return (
    <section className={`relative pt-16 ${className}`}>
      <img
        src={bgImage || getPublicURL(`/theme/assets/images/responsive/portrait-bg.svg`)}
        style={{ zIndex: '-1', height: '100%' }}
        className="absolute inset-0 w-full"
      />
      <Topbar float />
      {children}
    </section>
  );
};

export default Portrait;
