import { GatsbyImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import tw from 'twin.macro';

const Container = styled.article`
  ${tw`flex flex-col h-auto items-center`}

  & h1,
  & p {
    ${tw`text-center my-3 lg:my-6 text-white font-thin leading-snug`}
  }

  & h1 {
    ${tw`text-3xl md:text-4xl`}
  }

  & p {
    ${tw`md:text-xl mx-2`}
  }
`;

export { Container };
